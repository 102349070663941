import Component, { mixins } from 'vue-class-component';
import DataTableMixin from '@/core/mixins/data-table.mixin';

import PartnerModule from '@/store/modules/partner';
import AppWithdrawalFormDialog from '../components/WithdrawalFormDialog.vue';

@Component({
  name: 'AppPartnerFinance',
})
export default class PartnerFinanceComponent extends mixins(DataTableMixin) {
  headers: TableHeaderItem[] = [
    {
      text: 'Номер заявки',
    },
    {
      text: 'Дата зявки',
    },
    {
      text: 'Дата выплаты',
    },
    {
      text: 'Реквизиты',
    },
    {
      text: 'Сумма',
    },
  ];

  // Computed
  get stats(): PartnerStats {
    return PartnerModule.stats;
  }

  // Lifecycle

  // Methods
  openWithdrawalDialog() {
    this.$dialog.open({ component: AppWithdrawalFormDialog });
  }

  handleRequest() {}
}
