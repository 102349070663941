


































































import Vue from 'vue';
import Component from 'vue-class-component';
import PartnerModule from '@/store/modules/partner';
import { Validators } from 'shared/utils/validator';

@Component({
  popupOptions: {
    size: 'sm',
  },
  name: 'AppWithdrawalFormDialog',
})
export default class WithdrawalFormDialog extends Vue {
  // Data
  model: any = {
    sum: 0,
    wallet: 0,
    data: '',
  };

  wallets: SimpleObject<string> = {
    0: 'Выбрать',
    invoice: 'На расчётный счёт',
    card: 'Банковская карта (с названием банка)',
  };

  // Computed
  get stats(): PartnerStats {
    return PartnerModule.stats;
  }

  get auth(): User {
    return this.$store.state.auth.user;
  }

  // Lifecycle
  created() {
    this.model.sum = this.stats.current_balance;
  }

  // methods
  async submit() {
    const template = `
      <a href="https://egrn.realtycloud.ru/admin/user/${this.auth.id}/finance">CRM профиль ${this.auth.email}</a><br><br>
      Email: <a href="mailto:${this.auth.email}">${this.auth.email}</a><br>
      Сумма: <b>${this.model.sum}</b><br>
      Данные для вывода: <b>${this.wallets[this.model.wallet]}</b><br>
      Реквизиты: <b>${this.model.data}</b>
    `;

    try {
      await this.$api.events.SendEmail({
        template,
        email: 'partners@realtycloud.ru',
        title: 'Вывод средств',
      });

      this.$noty.info({ text: 'Запрос успешно отправлен' });
      this.$dialog.close();
    } catch (error) {
      console.log(error);
      this.$noty.error({ text: 'Произошла ошибка при отправке, попробуйте позже' });
    }
  }

  validationConfig(): ValidationConfig {
    return {
      sum: [
        Validators.required,
        Validators.isInt({
          message: `Сумма для вывода должна быть от 3000 руб. и не больше суммы на балансе`,
          min: 3000,
          max: parseFloat(this.stats.current_balance),
        }),
      ],
      wallet: [ Validators.notEq(0, 'Выберите способ для зачисления') ],
      data: [ Validators.required, Validators.min(5) ],
    };
  }
}
