var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("h6", { staticClass: "text-muted mb-3 px-4" }, [
      _vm._v("\n    Ваш уникальный партнёр ID: "),
      _c(
        "b",
        {
          staticClass: "link small",
          attrs: {
            "data-clipboard-text": _vm.user.id,
            "data-title": "Партнёр ID скопирован"
          }
        },
        [
          _vm._v(_vm._s(_vm.user.id) + " "),
          _c("i", { staticClass: "fas fa-clipboard" })
        ]
      )
    ]),
    _c("div", { staticClass: "page-wrapper page-wrapper_border" }, [
      _vm._m(0),
      _c("div", { staticClass: "partner-ref-links" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-4 partner-ref-links__item",
              attrs: {
                "data-title": "Ссылка скопирована",
                "data-clipboard-text":
                  "https://egrn.realtycloud.ru/?partner_id=" + _vm.user.id
              }
            },
            [
              _c("div", { staticClass: "link text-truncate small" }, [
                _vm._v(
                  "\n            https://egrn.realtycloud.ru/?partner_id=" +
                    _vm._s(_vm.user.id) +
                    "\n          "
                )
              ]),
              _c("div", { staticClass: "partner-ref-links__title" }, [
                _vm._v(
                  "\n            Для привлечения на Отчеты ЕГРН\n          "
                )
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-4 partner-ref-links__item",
              attrs: {
                "data-title": "Ссылка скопирована",
                "data-clipboard-text":
                  "https://b2b.realtycloud.ru/?partner_id=" + _vm.user.id
              }
            },
            [
              _c("div", { staticClass: "link text-truncate small" }, [
                _vm._v(
                  "\n            https://b2b.realtycloud.ru/?partner_id=" +
                    _vm._s(_vm.user.id) +
                    "\n          "
                )
              ]),
              _c("div", { staticClass: "partner-ref-links__title" }, [
                _vm._v("\n            Для привлечения B2B клиентов\n          ")
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-4 partner-ref-links__item",
              attrs: {
                "data-title": "Ссылка скопирована",
                "data-clipboard-text":
                  "https://b2b.realtycloud.ru/risk?partner_id=" + _vm.user.id
              }
            },
            [
              _c("div", { staticClass: "link text-truncate small" }, [
                _vm._v(
                  "\n            https://b2b.realtycloud.ru/risk?partner_id=" +
                    _vm._s(_vm.user.id) +
                    "\n          "
                )
              ]),
              _c("div", { staticClass: "partner-ref-links__title" }, [
                _vm._v(
                  "\n            Для привлечения на Отчет о Рисках через статью\n          "
                )
              ])
            ]
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "page-wrapper page-wrapper_border mt-3" }, [
      _vm._m(1),
      _c("div", { staticClass: "admin-page-head" }, [
        _c("h6", [_vm._v("Форма")]),
        _c("p", [
          _vm._v(
            "\n        HTML код который можно установить на любое количество страниц сайта или на разные сайты.\n        Для вставки формы на свой сайт, скопируйте следующий код и вставьте в HTML страницу своего сайта.\n      "
          )
        ]),
        _c("code", { staticClass: "form-code mb-0" }, [
          _c(
            "pre",
            {
              attrs: {
                id: "code",
                "data-clipboard-target": "#code",
                "data-title": "HTML код скопирован"
              }
            },
            [_vm._v(_vm._s(_vm.formCode))]
          )
        ])
      ]),
      _c("div", { staticClass: "admin-page-head" }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center justify-content-between" },
          [
            _c("h6", [_vm._v("Дополнительная настройка формы")]),
            _c("div", { staticClass: "link", on: { click: _vm.previewForm } }, [
              _vm._v("\n          Предпросмотр\n        ")
            ])
          ]
        ),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("b", [_vm._v("Заголовки")]),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("div", { staticClass: "form-label" }, [
                  _vm._v("\n              Верхний заголовок\n            ")
                ]),
                _c("app-input", {
                  model: {
                    value: _vm.title,
                    callback: function($$v) {
                      _vm.title = $$v
                    },
                    expression: "title"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("div", { staticClass: "form-label" }, [
                  _vm._v("\n              Подзаголовок\n            ")
                ]),
                _c("app-input", {
                  model: {
                    value: _vm.subtitle,
                    callback: function($$v) {
                      _vm.subtitle = $$v
                    },
                    expression: "subtitle"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("div", { staticClass: "form-label" }, [
                  _vm._v("\n              Внутренний заголовок\n            ")
                ]),
                _c("app-input", {
                  model: {
                    value: _vm.orderTitle,
                    callback: function($$v) {
                      _vm.orderTitle = $$v
                    },
                    expression: "orderTitle"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("div", { staticClass: "form-label" }, [
                  _vm._v("\n              Текст кнопки\n            ")
                ]),
                _c("app-input", {
                  model: {
                    value: _vm.buttonText,
                    callback: function($$v) {
                      _vm.buttonText = $$v
                    },
                    expression: "buttonText"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("div", { staticClass: "form-label" }, [
                  _vm._v("\n              Изображение\n            ")
                ]),
                _c("app-input", {
                  attrs: { placeholder: "URL изображения" },
                  model: {
                    value: _vm.imageUrl,
                    callback: function($$v) {
                      _vm.imageUrl = $$v
                    },
                    expression: "imageUrl"
                  }
                }),
                _c(
                  "app-checkbox",
                  {
                    model: {
                      value: _vm.disableImage,
                      callback: function($$v) {
                        _vm.disableImage = $$v
                      },
                      expression: "disableImage"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Отключить изображение\n            "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c("b", [_vm._v("Продукты для продажи")]),
                _vm._l(_vm.products, function(p) {
                  return _c(
                    "div",
                    { key: p.product_id, staticClass: "partner-product py-1" },
                    [
                      _c(
                        "app-checkbox",
                        {
                          attrs: { value: p.product_name },
                          on: { change: _vm.productsChange },
                          model: {
                            value: _vm.selectedProducts,
                            callback: function($$v) {
                              _vm.selectedProducts = $$v
                            },
                            expression: "selectedProducts"
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(p.product_name_ru) +
                              "\n              "
                          )
                        ]
                      )
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c("b", [_vm._v("Выбранные продукты по умолчанию")]),
                _vm._l(_vm.selectedProducts, function(p) {
                  return _c(
                    "div",
                    { key: p, staticClass: "partner-product py-1" },
                    [
                      _c(
                        "app-checkbox",
                        {
                          attrs: { value: p },
                          model: {
                            value: _vm.defaultChecked,
                            callback: function($$v) {
                              _vm.defaultChecked = $$v
                            },
                            expression: "defaultChecked"
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.productsByName[p].product_name_ru) +
                              "\n              "
                          )
                        ]
                      )
                    ],
                    1
                  )
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c("b", [_vm._v("Срочные заказы")]),
                _c(
                  "app-checkbox",
                  {
                    model: {
                      value: _vm.enableFastProducts,
                      callback: function($$v) {
                        _vm.enableFastProducts = $$v
                      },
                      expression: "enableFastProducts"
                    }
                  },
                  [
                    _vm._v(
                      "\n              Позволять оформлять срочные заказы\n            "
                    )
                  ]
                ),
                _c("div", { staticClass: "small text-muted" }, [
                  _vm._v(
                    "\n              Срочный заказ доступен не во всех регионах, и имеет повышенную цену\n            "
                  )
                ])
              ],
              1
            ),
            _vm.isAdmin
              ? _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c("b", [_vm._v("Бесплатный заказ")]),
                    _c(
                      "app-checkbox",
                      {
                        model: {
                          value: _vm.allowFreeOrder,
                          callback: function($$v) {
                            _vm.allowFreeOrder = $$v
                          },
                          expression: "allowFreeOrder"
                        }
                      },
                      [
                        _vm._v(
                          "\n              Позволять оформлять бесплтную выписку о переходе прав\n            "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "admin-page-head " }, [
      _c("h5", [_vm._v("Реферальные ссылки")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "admin-page-head" }, [
      _c("h5", [_vm._v("Виджеты")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }