








import Vue from 'vue';
import loadExternalScript from 'shared/utils/loadExternalScript';

let vm: Vue;

export default Vue.extend({
  popupOptions: {
    name: 'popup_full',
  },

  props: {
    options: Object,
  },

  async mounted() {
    await loadExternalScript('https://realtycloud.ru/forms/custom/RealtyCloudEGRN.umd.min.js');

    vm = (window as any).RealtyCloudEGRN.init(this.$refs.form, this.options);
  },

  beforeDestroy() {
    if (vm) {
      vm.$destroy();
      vm = null;
    }
  },
});
