import { render, staticRenderFns } from "./Instruments.vue?vue&type=template&id=bd9159d0&scoped=true&"
import script from "./Instruments.ts?vue&type=script&lang=ts&"
export * from "./Instruments.ts?vue&type=script&lang=ts&"
import style0 from "./Instruments.scss?vue&type=style&index=0&id=bd9159d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd9159d0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/realtycloud/rcfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('bd9159d0', component.options)
    } else {
      api.reload('bd9159d0', component.options)
    }
    module.hot.accept("./Instruments.vue?vue&type=template&id=bd9159d0&scoped=true&", function () {
      api.rerender('bd9159d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Admin/Partner/Instruments/Instruments.vue"
export default component.exports