import Vue from 'vue';
import Component from 'vue-class-component';
import ClipboardJS from 'clipboard';
import includes from 'lodash/includes';
import keyBy from 'lodash/keyBy';

import AppFormPreviewDialog from '../components/FormPreviewDialog.vue';
import { NULL_UUID } from 'shared/utils/constants';
import { AuthTypes } from 'shared/store/modules/auth/types';

@Component
export default class PartnerInstruments extends Vue {
  //
  private clipboard: ClipboardJS;

  // data
  availableProducts: ProductName[] = [ 'EgrnObject', 'EgrnRightList', 'RiskAssessment' ];
  selectedProducts: ProductName[] = [ 'EgrnObject', 'EgrnRightList' ];
  defaultChecked: ProductName[] = [ 'EgrnObject' ];
  enableFastProducts: boolean = false;
  allowFreeOrder: boolean = false;

  title: string = '';
  subtitle: string = '';
  imageUrl: string = '';
  disableImage: boolean = false;
  buttonText: string = '';
  orderTitle: string = '';

  get isAdmin(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ADMIN];
  }

  get user(): User {
    return this.$store.state.auth.user;
  }

  get products(): Product[] {
    return this.$store.state.products.filter((product: Product) => {
      return includes(this.availableProducts, product.product_name);
    });
  }

  get productsByName(): { [key: string]: Product } {
    return keyBy(this.products, 'product_name');
  }

  get formCode(): string {
    const options = [
      `    partnerId: "${this.user.id}"`,
    ];

    if (this.title) {
      options.push(`    title: "${this.title}"`);
    }

    if (this.subtitle) {
      options.push(`    subtitle: "${this.subtitle}"`);
    }

    if (this.orderTitle.length) {
      options.push(`    orderTitle: "${this.orderTitle}"`);
    }

    if (this.buttonText.length) {
      options.push(`    buttonText: "${this.buttonText}"`);
    }

    if (this.selectedProducts.length) {
      options.push(`    selectedProducts: [${this.selectedProducts.map(n => `"${n}"`).join(', ')}]`);
    }

    if (this.defaultChecked.length) {
      options.push(`    defaultCheckedProducts: [${this.defaultChecked.map(n => `"${n}"`).join(', ')}]`);
    }

    if (this.enableFastProducts) {
      options.push('    enableFastProducts: true');
    }

    if (this.allowFreeOrder) {
      options.push('    allowFreeOrder: true');
    }

    if (this.imageUrl) {
      options.push(`    imageUrl: "${this.imageUrl}"`);
    }

    if (this.disableImage) {
      options.push('    disableImage: true');
    }

    return [
      '<script>',
      '  window.__RC_OPTIONS__ = {',
      options.join(', \n'),
      '  };',
      '</script>',
      '<script src="https://realtycloud.ru/forms/custom/RealtyCloudEGRN.umd.min.js"></script>',
    ].join('\n');
  }

  mounted() {
    this.clipboard = new ClipboardJS('[data-clipboard-text], [data-clipboard-target]');
    this.clipboard.on('success', (event: ClipboardJS.Event) => {
      const title = event.trigger.getAttribute('data-title');

      this.$noty.info({
        text: `<div class="small"><b>${title}</b> <br> ${event.text}</div>`,
      });
    });
  }

  beforeDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
      this.clipboard = null;
    }
  }

  // Methods
  productsChange(products: ProductName[]) {
    this.defaultChecked = this.defaultChecked.filter(p => {
      return includes(products, p);
    });
  }

  previewForm() {
    const options: any = {
      partnerId: NULL_UUID,
      enableFastProducts: this.enableFastProducts,
      preventAutoInit: true,
    };

    if (this.title) {
      options.title = this.title;
    }

    if (this.subtitle) {
      options.subtitle = this.subtitle;
    }

    if (this.orderTitle) {
      options.orderTitle = this.orderTitle;
    }

    if (this.buttonText) {
      options.buttonText = this.buttonText;
    }

    if (this.defaultChecked.length) {
      options.defaultCheckedProducts = this.defaultChecked;
    }

    if (this.selectedProducts.length) {
      options.selectedProducts = this.selectedProducts;
    }

    if (this.imageUrl.length) {
      options.imageUrl = this.imageUrl;
    }

    options.disableImage = this.disableImage;

    this.$dialog.open({
      component: AppFormPreviewDialog,
      props: {
        options,
      },
    });
  }
}
